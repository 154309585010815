import * as React from "react";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
// Importing inbuilt lib's css
import "react-mde/lib/styles/css/react-mde-all.css";



// enabling to show markdown as normal text 
const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true
  });

export default function App({ currentNote, updateNote }){

    // All code from viraj-cz/simple markdown editor repo

    const [selectedTab, setSelectedTab] = React.useState("write");
    return(
        <section className="pane editor">
            <ReactMde
                value={currentNote.body}
                onChange={updateNote}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
                minEditorHeight={80}
                heightUnits="vh"
            />
        </section>
    )
}