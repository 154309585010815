import React from "react";


export default function Sidebar(props){

    //passing notes & currentNote state from App.js
    const noteElements = props.notes.map((note, index) => (
        <div key={note.id}>
            <div
                
                className={`title ${
                    note.id === props.currentNote.id ? "selected-note" : ""
                }`}
                onClick={() => props.setCurrentNoteId(note.id)}
            >
                {/*just using very fancy way of grabbing the title of the Markdown*/}
                <h4 className="text-snippet">{note.body.split("\n")[0]}</h4>

                {/*A simple delete button to delete notes*/}
                <button 
                    className="delete-btn"
                    onClick={(event) => props.deleteNote(event, note.id)}

                >
                    <i className="gg-trash trash-icon"></i>
                </button>
            </div>
        </div>
    ))
    
    return(
        <section className="pane sidebar">
            <div className="sidebar--header">
                <h3>Subtle</h3>
                <button className="new-note" onClick={props.newNote}>+</button>
            </div>
            {noteElements}
        </section>
    )
}