import React from "react";
import Sidebar from "./Sidebar"
import Editor from "./Editor"
import Split from "react-split"
import {nanoid} from "nanoid"


export default function App(){

    //Declaring all the states here cause then they can be passed as props to the other child components

    //Declaring to states: 1) Note's content (an Array, to store all the notes) and 2) Currently's engaged Note ID
    // || reads as or
    //Adding ()=> before JSON.parse because it helps uf from preventing component from rendering all the time and use lazy state initialization
    const [notes, setNotes] = React.useState(()=>
        JSON.parse(localStorage.getItem("notes")) || [])
    const [currentNoteId, setCurrentNoteId] = React.useState(
        (notes[0] && notes[0].id) || ""
    )

    //A useEffect() to push changes in notes to local storage
    React.useEffect(() => {
        localStorage.setItem("notes", JSON.stringify(notes))
    }, [notes])

    // A func to create a new note:
    function createNewNote(){
        const newNote = {
            // assigning a random ID using nanoid
            id: nanoid(),
            body: "# Title goes here",
        }

        //Using setState of Note content and current note ID

        //Adding the new note in Notes Array
        setNotes(prevNotes => [newNote, ...prevNotes])
        // Setting the new Note's ID as current note ID
        setCurrentNoteId(newNote.id)
    }

    // A func to delete notes
    function deleteNote(event, noteId) {
        event.stopPropagation()
        setNotes(oldNotes => oldNotes.filter(note => note.id !== noteId))
    }

    // A func to update notes -- Takes text as input to update state
    function updateNote(text) {
        // Put the most recently-modified note at the top
        setNotes(oldNotes => {
            const newArray = []
            for(let i = 0; i < oldNotes.length; i++) {
                const oldNote = oldNotes[i]
                if(oldNote.id === currentNoteId) {
                    newArray.unshift({ ...oldNote, body: text })
                } else {
                    newArray.push(oldNote)
                }
            }
            return newArray
        })
    }

    // A func to  find the current note
    function findCurrentNote() {
        // The find() method returns the first element in the provided array that satisfies the provided testing function 
        // This reads as -- Return the note with note ID equal to current note ID, else return the note with 0 index of array
        return notes.find(note => {
            return note.id === currentNoteId
        }) || notes[0]
    }

    // Using conditional render to set an if else for rendering based on the content available
    function Display(){
        if(notes.length>0){
            return (
            <Split 
                sizes={[30, 70]} 
                direction="horizontal" 
                className="split"
            >
                <Sidebar
                    notes={notes}
                    currentNote={findCurrentNote()}
                    setCurrentNoteId={setCurrentNoteId}
                    newNote={createNewNote}
                    deleteNote={deleteNote}

                />
                {
                    currentNoteId && 
                    notes.length > 0 &&
                    <Editor 
                        currentNote={findCurrentNote()} 
                        updateNote={updateNote} 
                    />
                }
            </Split>
            )
        }else{
            return(
                <div className="no-notes">
                <h1>You have no notes</h1>
                <button 
                    className="first-note" 
                    onClick={createNewNote}
                >
                    Create one now
                </button>
            </div>
            )
        }
    }

    return(
        <div className="main-app">
            {Display()}
            
        </div>
    )
}